export const topMenu = [
    {
        title: 'Home',
        link: '#home'
    },
    {
        title: 'Features',
        link: '#features'
    },
    {
        title: 'Faq',
        link: '#faq'
    },
    {
        title: 'Uptime',
        link: '#uptime'
    },
    // {
    //     title: 'Contact',
    //     link: '#contact'
    // }
];