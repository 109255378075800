import React from 'react';
import { CentOs, Debian, Ubuntu } from './svgs';

const Supports = () => {
    return (
        <div>
            <h3>Support Distributions (Tested)</h3>
            <div className="supports-wrap">
                { _LIST.map((item, idx) => {
                    return (
                        <div
                            key={ item.title + '23z' }
                            title={ item.title }>
                        <span className="icon-ez">
                            { item.icon }
                        </span>
                        </div>
                    );
                }) }
            </div>
        </div>
    );
};

export default Supports;

const _LIST = [
    {
        title: 'CentOs',
        icon: <CentOs/>,
        version: '5, 6, 7'
    },
    {
        title: 'Ubuntu',
        icon: <Ubuntu/>,
        version: '10, 12, 14, 16, 18, 20'
    },
    {
        title: 'Debian',
        icon: <Debian/>,
        version: '6, 7, 8, 9, 10, 11'
    }
];