import React, { useEffect, useState } from 'react';
import Loader from './loader';
import styled from 'styled-components';

const H2Styled = styled.h2`
  position: relative;
`;

const TotalServersCount = () => {
    const [loader, setLoader] = useState(true);
    const [serversCount, setServersCount] = useState(0);

    useEffect(async () => {
        const getServersCount = async () => {
            let response1;
            await fetch(
                new Request('https://api.syagent.com/servers-count',
                    { method: 'GET' }
                )).then(response => {
                response1 = response.json();
            });

            return response1;
        };

        const response = await getServersCount();

        if (response && response.data && response.data.data_o &&
            response.data.data_o.servers) {
            setServersCount(response.data.data_o.servers);
            setLoader(false);
        }
    }, []);

    return (
        <H2Styled>
            Total servers monitored : <span>{ loader
            ? <Loader/>
            : serversCount }</span>
        </H2Styled>
    );
};

export default TotalServersCount;