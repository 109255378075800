import styled from 'styled-components';
import { withPrefix } from 'gatsby';

export const MainSectionStyled = styled.section`
  width: 900px;
  margin: 24px auto;

  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 24px 3%;
  }
  
  .kanula{
    color: rgb(0, 188, 212);
  }

  .ldoa {
    display: grid;
    //grid-template-columns: 1fr 1fr;
    //grid-gap: 60px;
    justify-content: center;
  }
`;

export const MainStyled = styled.main`
  margin: 93px 0;

  @media screen and (max-width: 800px) {

  }
`;

export const HeaderStyled = styled.nav`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: baseline;
  grid-gap: 60px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
    text-align: center;
  }
`;

export const LogoStyled = styled.div`
  font-size: 20px;

  span {
    font-size: 11px;
    margin-left: 7px;
    color: rgb(0, 188, 212);
  }
`;

export const MenuStyled = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  //@media screen and (max-width: 800px) {
  //  grid-template-columns: 1fr 1fr 1fr;
  //}

  li {

  }
`;

export const LoginSectionStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

export const ButtonStyled = styled.button`

`;

export const PrimaryButtonStyled = styled(ButtonStyled)`

`;

export const BigPromoStyled = styled.section`
  width: 725px;
  margin: auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  h1 {
    font-size: 62px;
    text-align: center;
    margin: 0;
  }

  h2 {
    text-align: center;
    margin: 27px;
    font-size: 20px;
    font-weight: 300;
  }

  .start-for-free-wrapper {
    text-align: center;
    margin: 70px;
  }
`;

export const DashboardImageStyled = styled.div`
  img {
    width: 100%;
    //transform: rotateX(60deg) rotateY(60deg) rotateZ(60deg);
  }
`;

export const FeaturesStyled = styled.section`
  margin: 70px 0;

  h3 {
    font-size: 37px;
    text-align: center;
    font-weight: 100;
  }
`;

export const UptimeStyled = styled.section`
  margin: 70px 0;

  h3 {
    font-size: 37px;
    text-align: center;
    font-weight: 100;
  }
  
  h4{
    text-align: center;
    font-size: 20px;
    margin: 18px 0 10px;
  }
  
  .ldoa{
    img{
      max-width: 100%;
    }
  }
`;


export const FeatureItemStyled = styled.div`
  margin: 35px 0 80px;

  h4 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }

  img {
    max-width: 100%;
  }
`;

export const PricingStyled = styled.section`

`;

export const ContactUsStyled = styled.section`

`;

export const FaqStyled = styled.section`

`;

export const FaqContentStyled = styled.div`
  h3 {
    font-size: 37px;
    text-align: center;
    font-weight: 100;
  }
`;

export const FaqListStyled = styled.div`
  margin: 20px 0;
  text-align: center;
`;

export const FaqListItemStyled = styled.div`
  margin: 15px 0;

  .title {
    font-size: 21px;
  }

  .body {
    word-break: break-all;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin: 10px 0 0 10px;
    line-height: 1.6;
  }
`;

export const SupportsStyled = styled.section`
  margin: 70px 0;

  h3 {
    font-size: 37px;
    text-align: center;
    font-weight: 100;
  }

  h4 {
    text-align: center;
    font-size: 18px;
    margin: 10px;
  }

  .supports-wrap {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    align-items: center;
    justify-items: center;
    margin: 15px auto;
    width: fit-content;
  }

  .icon-ez {
    svg {
      width: 80px;
      height: 80px;
    }
  }
`;