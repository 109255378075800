import React from 'react';
import { withPrefix } from 'gatsby';

const Uptime = () => {
    return (
        <div>
            <h3>Uptime</h3>
            <h4>HTTP, PING, PUSH, more soon</h4>
            <div className="ldoa">
                <img src={ `${ withPrefix('/images/bukoz.png') }` } alt={ 'Uptime' }/>
            </div>
        </div>
    );
};

export default Uptime;