import React from 'react';
import {
    FaqContentStyled,
    FaqListItemStyled,
    FaqListStyled
} from '../styles/styled';

const Faq = () => {
    return (
        <FaqContentStyled>
            <h3>FAQ</h3>
            <FaqListStyled>
                { _FAQ_DATA.map((item, idx) => {
                    const pewska = { __html: item.content };
                    return (<FaqListItemStyled
                        key={ 'ddzr' + idx + item.id }
                    >
                        <div className="title">
                            { item.title }
                        </div>
                        <div className="body"
                             dangerouslySetInnerHTML={ pewska }/>
                    </FaqListItemStyled>);
                }) }
            </FaqListStyled>
        </FaqContentStyled>
    );
};

export default Faq;

const _FAQ_DATA = [
    {
        id: '33',
        title: 'Why is it free?',
        content: 'Simply because this project is a hobby and run by one man'
    },
    {
        id: '333',
        title: 'Will it stay free?',
        content: 'Yes!'
    },
    {
        id: '333',
        title: 'How can I get notification if my server have problems?',
        content: 'We send notifications via Email, Telegram, Pushsafer, Pushover and Webhook. More will be added soon'
    },
    {
        id: '34',
        title: 'Are there any limitations?',
        content: 'Current limitations are: \n' +
            '1. 25 servers and 25 uptime monitoring per user\n' +
            '2. Metrics are stored for 7 days\n' +
            '3. Unread alarms are stored for 2 month'
    },
    {
        id: '133',
        title: 'How can I help you?',
        content: 'You can <a href="https://www.buymeacoffee.com/syagent" target="_blank">Buy me a coffee</a>'
    },
    {
        id: '233',
        title: 'Where are syagent servers are located?',
        content: 'Syagent servers are located in Nuremberg (Germany), hosted in Hetzner.\nHere is a referral link if you need servers and help syagent - <a href="https://hetzner.cloud/?ref=qqkc2PgALM77" target="_blank">Hetzner</a>'
    },
    {
        id: '5533',
        title: 'How can I contribute to syagent?',
        content: '1. You can contribute to our agent and help to make it better - <a href="https://github.com/syagent/agent-2" target="_blank">Agent</a>\n' +
            '2. Suggest features - <a href="https://github.com/syagent/agent-2/discussions" target="_blank">Discussions</a>\n' +
            '3. Found bug? - <a href="https://github.com/syagent/agent-2/issues" target="_blank">Issues</a>'
    },
    {
        id: '33994',
        title: 'How can I contact you?',
        content: 'Drop me an email to hi@syagent.com'
    }
];