import * as React from 'react';
import '../styles/reset.css';
import '../styles/global.css';
import { withPrefix } from 'gatsby';
import { PrimaryButton, DefaultButton } from '@fluentui/react';

import {
    BigPromoStyled,
    ContactUsStyled,
    DashboardImageStyled, FaqStyled,
    FeaturesStyled,
    HeaderStyled,
    LoginSectionStyled,
    LogoStyled,
    MainSectionStyled,
    MainStyled,
    MenuStyled,
    SupportsStyled, UptimeStyled
} from '../styles/styled';
import { topMenu } from '../data/menu';
import FeatureList from '../components/featureList';
import { Helmet } from 'react-helmet';
import Faq from '../components/faq';
import Supports from '../components/supports';
import TotalServersCount from '../components/totalServersCount';
import Uptime from '../components/uptime';

const navigater = (e, uri) => {
    e.preventDefault();
    setTimeout(() => {
        document.getElementById(uri)
            .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
};

const IndexPage = () => {
    return (
        <MainSectionStyled>
            {/*<Helmet title="SyAgent - Easy server monitoring" defer={false}>*/ }
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="description"
                      content="Free Easy linux server and uptime monitoring in few seconds. Alarms, charts, monitoring cpu, memory, disks, sessions, network"/>
                <title>SyAgent - Easy server, uptime and mongodb monitoring</title>
            </Helmet>
            <HeaderStyled>
                <LogoStyled>
                    SyAgent
                    <span>BETA</span>
                </LogoStyled>
                <MenuStyled>
                    { topMenu.map(link => {
                        return (
                            <li key={ link.title }>
                                <a
                                    onClick={ (e) => navigater(e, link.link) }
                                    href={ link.link }>{ link.title }</a>
                            </li>
                        );
                    }) }
                </MenuStyled>
                <LoginSectionStyled>
                    <PrimaryButton
                        text={ 'Join' } href="https://app.syagent.com/register"
                    />

                    <DefaultButton
                        text={ 'Login' } href="https://app.syagent.com/login"
                    />
                </LoginSectionStyled>
            </HeaderStyled>
            <MainStyled id="#home">
                <BigPromoStyled>
                    <h1>
                        Free <span className="kanula">Server</span> and <span
                        className="kanula">Uptime</span> Monitoring Solution in few seconds
                    </h1>
                    <TotalServersCount/>
                    <div className="start-for-free-wrapper">
                        <PrimaryButton
                            text={ 'Start' }
                            href="https://app.syagent.com/register"
                        />
                    </div>
                    <DashboardImageStyled>
                        <img src={ `${ withPrefix('/images/d.png') }` } alt=""/>
                    </DashboardImageStyled>
                </BigPromoStyled>
                <FeaturesStyled id="#features">
                    <FeatureList/>
                </FeaturesStyled>
                <UptimeStyled id="#uptime">
                    <Uptime/>
                </UptimeStyled>
                <SupportsStyled>
                    <Supports/>
                </SupportsStyled>
                {/*<PricingStyled id="#pricing">*/ }
                {/*    <Pricing/>*/ }
                {/*</PricingStyled>*/ }
                <FaqStyled id="#faq">
                    <Faq/>
                </FaqStyled>
                <ContactUsStyled id="#contact">

                </ContactUsStyled>
            </MainStyled>
        </MainSectionStyled>
    );
};

export default IndexPage;
