import React from 'react';
import { FeatureItemStyled } from '../styles/styled';
import { withPrefix } from 'gatsby';

const FeatureItem = props => {
    const { title, imageUrl, imageAlt } = props;

    return (
        <FeatureItemStyled>
            <h4>{ title }</h4>
            <div className='ldoa'>
                <img src={ `${ withPrefix(imageUrl) }` } alt={ imageAlt }/>
                {/*<div className="text">*/}
                {/*    {props.children}*/}
                {/*</div>*/}
            </div>
        </FeatureItemStyled>
    );
};

export default FeatureItem;