import React from 'react';
import FeatureItem from './featureItem';

const _LIST = [
    {
        title: 'Alarms are FREE. Wait what? Yes FREE!',
        image: '/images/daw.png'
    },
    {
        title: 'Resources',
        image: '/images/dcz.png'
    },
    {
        title: 'Processes',
        image: '/images/faw.png'
    },
    {
        title: 'Memory',
        image: '/images/kkkd.png'
    }
];

const FeatureList = () => {
    return (
        <div>
            <h3>Features</h3>
            { _LIST.map(item => {
                return (
                    <FeatureItem
                        key={ `dd_${ item.title }` }
                        title={ item.title }
                        imageUrl={ item.image }
                        imageAlt={ item.title }
                    />
                );
            }) }
        </div>
    );
};

export default FeatureList;